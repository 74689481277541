import React, { useEffect, useState, useRef } from 'react';
import logo from './../logo.svg';

import { TerminalContextProvider, ReactTerminal } from 'react-terminal';
import { useKeycloak } from '@react-keycloak/web';

const commandCategories = [
  {
    category: 'General',
    commands: [
      'help',
      'change-prompt',
      'change-theme',
      'toggle-control-bar',
      'toggle-control-buttons',
      'evaluate-math-expression',
      'account',
      'logout',
    ],
  },
  { category: 'Authentication', commands: ['auth'] },
  { category: 'DSL', commands: ['dsl'] },
  { category: 'Execution Orchestration', commands: ['eo'] },
  { category: 'Knowledge Graph', commands: ['kg'] },
  { category: 'S3', commands: ['s3'] },
  { category: 'User Management', commands: ['users'] },
];

// Command help information with descriptions and usage
const commandHelp = {
  // General Commands
  'help': {
    description: 'Display this help message or detailed help for a specific command',
    subcommands: [],
  },
  'change-prompt': {
    description: 'Change the current prompt',
    subcommands: [],
  },
  'change-theme': {
    description: 'Change the application theme',
    subcommands: [],
  },
  'toggle-control-bar': {
    description: 'Show or hide the control bar',
    subcommands: [],
  },
  'toggle-control-buttons': {
    description: 'Show or hide the control buttons',
    subcommands: [],
  },
  'evaluate-math-expression': {
    description: 'Evaluate a mathematical expression using MathJS API',
    subcommands: [],
  },
  'account': {
    description: 'Open account management page',
    subcommands: [],
  },
  'logout': {
    description: 'Log out of the current session',
    subcommands: [],
  },
  // Authentication Commands
  'auth': {
    description: 'Manage authentication operations',
    subcommands: ['token', 'userinfo', 'token-refresh', 'users-create'],
  },
  // DSL Commands
  'dsl': {
    description: 'Manage DSL operations',
    subcommands: [
      'workflows',
      'workflow',
      'workflow-delete',
      'workflow-start',
      'workflow-stop',
      'insitu-data-upload',
      'marketplace',
    ],
  },
  // Execution Orchestration Commands
  'eo': {
    description: 'Manage execution orchestration operations',
    subcommands: [
      'conformance',
      'well-known-open-eo',
      'credentials-basic',
      'credentials-oidc',
      'me',
      'processes',
      'process-graphs',
      'process-graph',
      'process-graph-update',
      'process-graph-delete',
      'file-formats',
      'result',
      'jobs',
      'jobs-create',
      'job-update',
      'job',
      'job-delete',
      'job-estimate',
      'job-logs',
      'job-results',
      'job-results-create',
      'job-results-delete',
      'collections',
      'collection',
      'collection-queryables',
      'service-types',
      'services',
      'services-create',
      'service-update',
      'service',
      'service-delete',
      'service-logs',
      'files',
      'file-upload',
      'file',
      'file-delete',
    ],
  },
  // Knowledge Graph Commands
  'kg': {
    description: 'Manage knowledge graph operations',
    subcommands: [
      'start-service',
      'service-status',
      'execute-query',
      'execute-advanced-query',
      'search-basic-meta-data-set',
      'search-data-set',
      'search-data-set-v1',
      'search-data-set-v2',
      'search-related-data-sets',
      'search-full-metadata',
      'search-dataset-breakdown',
      'search-dataset-breakdown-post',
      'search-dataset-breakdown-with-option',
      'generate-api-call',
      'generate-api-call-post',
    ],
  },
  // S3 Commands
  's3': {
    description: 'Manage S3 storage operations',
    subcommands: [
      'bucket-create',
      'buckets',
      'bucket',
      'bucket-delete',
      'bucket-files-upload',
      'bucket-files',
      'bucket-files-preview',
      'bucket-files-delete',
      'files-upload',
      'files-upload-conf',
      'files-js-def',
    ],
  },
  // User Management Commands
  'users': {
    description: 'Manage user accounts',
    subcommands: ['count', 'list', 'create', 'get', 'update', 'delete', 'history', 'credentials', 'reset-password'],
  },
};
export default function CustomTerminal() {
  const [theme, setTheme] = useState('dark');
  const [controlBar, setControlBar] = useState(true);
  const [controlButtons, setControlButtons] = useState(true);
  const [prompt, setPrompt] = useState('$');

  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI;

  const terminalRef = useRef(null);
  const scrollableRef = useRef(null);

  const fetchEndpoint = async (endpoint, { method = 'GET', queryParams = '', headers = {}, data = null } = {}) => {
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      ...headers,
    };
    const config = {
      method,
      headers: requestHeaders,
    };
    if (data && method !== 'GET') {
      config.body = JSON.stringify(data);
    }
    const url = `${API_URL}/${endpoint}${queryParams}`;
    try {
      const response = await fetch(url, config);
      const contentType = response.headers.get('content-type');

      if (response.ok) {
        if (contentType && contentType.includes('application/json')) {
          const result = await response.json();
          return result;
        } else {
          const text = await response.text();
          return text;
        }
      } else {
        let errorData;
        if (contentType && contentType.includes('application/json')) {
          errorData = await response.json();
        } else {
          errorData = await response.text();
        }

        // Check for 401 Unauthorized
        if (response.status === 401) {
          // If errorData is an object, add the reload message
          if (typeof errorData === 'object' && errorData !== null) {
            errorData.reloadSuggested = true;
            errorData.message = errorData.message || 'Unauthorized access';
            errorData.suggestion = 'Please reload the page to refresh your authentication';
          } else {
            // If errorData is a string, convert to object with reload info
            errorData = {
              status: 401,
              message: errorData || 'Unauthorized access',
              reloadSuggested: true,
              suggestion: 'Please reload the page to refresh your authentication',
            };
          }
        }

        return errorData;
      }
    } catch (e) {
      // Handle cases where response can't be parsed as JSON or network errors
      return JSON.stringify(
        {
          status: e.name === 'SyntaxError' ? 200 : 0,
          statusText: e.message,
          error: e,
          ...(e.status === 401 && {
            reloadSuggested: true,
            suggestion: 'Please reload the page to refresh your authentication',
          }),
        },
        null,
        2,
      );
    }
  };

  const commands = {
    // General Commands
    'help': (args) => {
      // Split args into array and filter out empty strings
      const argArray = args.split(' ').filter((arg) => arg.length > 0);

      // If no arguments or empty string, show all commands
      if (!argArray.length || argArray[0] === '') {
        let helpMessage = 'Available commands:\n\n';

        commandCategories.forEach((category) => {
          helpMessage += `${category.category}:\n`;
          category.commands.forEach((cmd) => {
            const cmdInfo = commandHelp[cmd];
            helpMessage += `  ${cmd} - ${cmdInfo.description}\n`;
          });
          helpMessage += '\n';
        });

        helpMessage += 'Use "help [command]" for more details about a specific command.';
        return helpMessage;
      }

      // If a command is specified, show detailed help for that command
      const requestedCommand = argArray[0].toLowerCase();

      if (commandHelp.hasOwnProperty(requestedCommand)) {
        const cmdInfo = commandHelp[requestedCommand];
        let detailedHelp = `${requestedCommand} - ${cmdInfo.description}`;

        if (cmdInfo.subcommands.length > 0) {
          detailedHelp += '\nSubcommands:\n';
          cmdInfo.subcommands.forEach((subcmd) => {
            detailedHelp += `  ${subcmd}\n`;
          });
        }

        return detailedHelp;
      }

      return `Command "${requestedCommand}" not found. Type "help" to see all available commands.`;
    },
    'change-prompt': (args) => {
      args = args.split(' ');
      if (args.length !== 1 || args[0] === '') return 'Usage: change-prompt <prompt>';
      setPrompt(args[0]);
      return 'Prompt changed to ' + args[0];
    },
    'change-theme': (args) => {
      args = args.split(' ');
      if (args.length !== 1 || args[0] === '') return 'Usage: change-theme <theme>';
      const validThemes = ['light', 'dark', 'material-light', 'material-dark', 'material-ocean', 'matrix', 'dracula'];
      if (!validThemes.includes(args[0])) return `Invalid theme. Valid themes: ${validThemes.join(', ')}`;
      setTheme(args[0]);
      return 'Theme changed to ' + args[0];
    },
    'toggle-control-bar': () => {
      return `Control bar ${controlBar ? 'hidden' : 'shown'}`;
    },
    'toggle-control-buttons': () => {
      return `Control buttons ${controlButtons ? 'hidden' : 'shown'}`;
    },
    'evaluate-math-expression': async (args) => {
      args = args.split(' ');
      if (args.length !== 1 || args[0] === '') return 'Usage: evaluate-math-expression <expression>';
      const expr = args[0];
      try {
        const response = await fetch(`https://api.mathjs.org/v4/?expr=${encodeURIComponent(expr)}`);
        return await response.text();
      } catch (e) {
        return 'Error evaluating expression: ' + e.message;
      }
    },
    'account': () => {
      window.open(keycloak.createAccountUrl());
      return 'Opening account management page...';
    },
    'logout': () => {
      window.open(keycloak.createLogoutUrl());
      return 'Logging out...';
    },
    // Auth Commands
    'auth': async (args) => {
      args = args.split(' ');
      const subcommand = args[0];
      const remainingArgs = args.slice(1).join(' ');
      switch (subcommand) {
        case 'token':
          if (!remainingArgs || remainingArgs.split(' ').length !== 3)
            return 'Usage: auth token <username> <password> <otp>';
          const [username, password, otp] = remainingArgs.split(' ');
          if (!username || !password || !otp) return 'Usage: auth token <username> <password> <otp>';
          try {
            const result = await fetchEndpoint('Auth/Token', {
              method: 'POST',
              headers: { username, password, otp },
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'userinfo':
          if (remainingArgs) return 'Usage: auth userinfo (no additional arguments required)';
          try {
            const result = await fetchEndpoint('Auth/Userinfo', { method: 'GET' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'token-refresh':
          if (!remainingArgs) return 'Usage: auth token-refresh <refresh_token>';
          const refreshToken = remainingArgs.trim();
          if (!refreshToken) return 'Usage: auth token-refresh <refresh_token>';
          try {
            const result = await fetchEndpoint('Auth/Token/Refresh', {
              method: 'POST',
              queryParams: `?refresh_token=${encodeURIComponent(refreshToken)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'users-create':
          if (!remainingArgs) return 'Usage: auth users-create <user_data_json>';
          const userDataJson = remainingArgs.trim();
          let userData;
          try {
            userData = JSON.parse(userDataJson);
          } catch (e) {
            return 'Error: Invalid JSON format for user_data_json';
          }
          try {
            const result = await fetchEndpoint('Auth/users/create', {
              method: 'POST',
              data: userData,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        default:
          return 'Available commands: auth token, auth userinfo, auth token-refresh, auth users-create';
      }
    },
    // DSL Commands
    'dsl': async (args) => {
      args = args.split(' ');
      const subcommand = args[0];
      const remainingArgs = args.slice(1).join(' ');

      switch (subcommand) {
        case 'workflows':
          const [status = '', withCfs = ''] = remainingArgs.split(' ');
          let queryParams = '';
          if (status !== '') queryParams += `?status=${encodeURIComponent(status)}`;
          if (withCfs !== '') queryParams += `${status !== '' ? '&' : '?'}withCfs=${encodeURIComponent(withCfs)}`;
          try {
            const result = await fetchEndpoint('DSL/dsl-api/workflows', { method: 'GET', queryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'workflow':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: dsl workflow <workflow_id>';
          const workflowID = remainingArgs.trim();
          if (!workflowID) return 'Usage: dsl workflow <workflow_id>';
          try {
            const result = await fetchEndpoint(`DSL/dsl-api/workflows/${workflowID}`, { method: 'GET' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'workflow-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: dsl workflow-delete <workflow_id>';
          const deleteWorkflowID = remainingArgs.trim();
          if (!deleteWorkflowID) return 'Usage: dsl workflow-delete <workflow_id>';
          try {
            const result = await fetchEndpoint(`DSL/dsl-api/workflows/${deleteWorkflowID}`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'workflow-start':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: dsl workflow-start <workflow_id>';
          const startWorkflowID = remainingArgs.trim();
          if (!startWorkflowID) return 'Usage: dsl workflow-start <workflow_id>';
          try {
            const result = await fetchEndpoint(`DSL/dsl-api/workflows/${startWorkflowID}/start`, { method: 'POST' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'workflow-stop':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: dsl workflow-stop <workflow_id>';
          const stopWorkflowID = remainingArgs.trim();
          if (!stopWorkflowID) return 'Usage: dsl workflow-stop <workflow_id>';
          try {
            const result = await fetchEndpoint(`DSL/dsl-api/workflows/${stopWorkflowID}/stop`, { method: 'GET' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'insitu-data-upload':
          return 'File upload not supported in terminal.';

        case 'marketplace':
          if (remainingArgs) return 'Usage: dsl marketplace (no additional arguments required)';
          try {
            const result = await fetchEndpoint('DSL/dsl-api/marketplace', { method: 'GET' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        default:
          return 'Available commands: dsl workflows, dsl workflow, dsl workflow-delete, dsl workflow-start, dsl workflow-stop, dsl insitu-data-upload, dsl marketplace';
      }
    },
    // EO Commands
    'eo': async (args) => {
      args = args.split(' ');
      const subcommand = args[0];
      const remainingArgs = args.slice(1).join(' ');

      switch (subcommand) {
        case 'conformance':
          if (remainingArgs) return 'Usage: eo conformance (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/conformance');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'well-known-open-eo':
          if (remainingArgs) return 'Usage: eo well-known-open-eo (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/.well-known/openeo');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'credentials-basic':
          if (remainingArgs) return 'Usage: eo credentials-basic (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/credentials/basic');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'credentials-oidc':
          if (remainingArgs) return 'Usage: eo credentials-oidc (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/credentials/oidc');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'me':
          if (remainingArgs) return 'Usage: eo me (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/me');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'processes':
          if (remainingArgs.split(' ').length > 1) return 'Usage: eo processes [limit]';
          const processLimit = remainingArgs.trim() || '';
          const processQueryParams = processLimit ? `?limit=${encodeURIComponent(processLimit)}` : '';
          try {
            const result = await fetchEndpoint('EO/processes', { queryParams: processQueryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'process-graphs':
          if (remainingArgs.split(' ').length > 1) return 'Usage: eo process-graphs [limit]';
          const pgLimit = remainingArgs.trim() || '';
          const pgQueryParams = pgLimit ? `?limit=${encodeURIComponent(pgLimit)}` : '';
          try {
            const result = await fetchEndpoint('EO/process_graphs', { queryParams: pgQueryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'process-graph':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: eo process-graph <process_graph_id>';
          const processGraphID = remainingArgs.trim();
          if (!processGraphID) return 'Usage: eo process-graph <process_graph_id>';
          try {
            const result = await fetchEndpoint(`EO/process_graphs/${processGraphID}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'process-graph-update':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: eo process-graph-update <process_graph_id> <process_graph_data_json>';
          const [pgID, pgDataJson] = remainingArgs.split(' ');
          if (!pgID || !pgDataJson)
            return 'Usage: eo process-graph-update <process_graph_id> <process_graph_data_json>';
          try {
            const processGraphData = JSON.parse(pgDataJson);
            const result = await fetchEndpoint(`EO/process_graphs/${pgID}`, {
              method: 'PUT',
              data: processGraphData,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'process-graph-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: eo process-graph-delete <process_graph_id>';
          const pgDeleteID = remainingArgs.trim();
          if (!pgDeleteID) return 'Usage: eo process-graph-delete <process_graph_id>';
          try {
            const result = await fetchEndpoint(`EO/process_graphs/${pgDeleteID}`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'file-formats':
          if (remainingArgs) return 'Usage: eo file-formats (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/file_formats');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'result':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo result <result_data_json>';
          const resultResultDataJson = remainingArgs.trim();
          if (!resultResultDataJson) return 'Usage: eo result <result_data_json>';
          try {
            const resultData = JSON.parse(resultResultDataJson);
            const result = await fetchEndpoint('EO/result', { method: 'POST', data: resultData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'jobs':
          if (remainingArgs.split(' ').length > 1) return 'Usage: eo jobs [limit]';
          const jobsLimit = remainingArgs.trim() || '';
          const jobsQueryParams = jobsLimit ? `?limit=${encodeURIComponent(jobsLimit)}` : '';
          try {
            const result = await fetchEndpoint('EO/jobs', { queryParams: jobsQueryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'jobs-create':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo jobs-create <job_data_json>';
          const jobDataJson = remainingArgs.trim();
          if (!jobDataJson) return 'Usage: eo jobs-create <job_data_json>';
          try {
            const jobData = JSON.parse(jobDataJson);
            const result = await fetchEndpoint('EO/jobs', { method: 'POST', data: jobData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-update':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: eo job-update <job_id> <job_data_json>';
          const [jobID, jobUpdateDataJson] = remainingArgs.split(' ');
          if (!jobID || !jobUpdateDataJson) return 'Usage: eo job-update <job_id> <job_data_json>';
          try {
            const jobData = JSON.parse(jobUpdateDataJson);
            const result = await fetchEndpoint(`EO/jobs/${jobID}`, { method: 'PATCH', data: jobData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo job <job_id>';
          const jobGetID = remainingArgs.trim();
          if (!jobGetID) return 'Usage: eo job <job_id>';
          try {
            const result = await fetchEndpoint(`EO/jobs/${jobGetID}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo job-delete <job_id>';
          const jobDeleteID = remainingArgs.trim();
          if (!jobDeleteID) return 'Usage: eo job-delete <job_id>';
          try {
            const result = await fetchEndpoint(`EO/jobs/${jobDeleteID}`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-estimate':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo job-estimate <job_id>';
          const jobEstimateID = remainingArgs.trim();
          if (!jobEstimateID) return 'Usage: eo job-estimate <job_id>';
          try {
            const result = await fetchEndpoint(`EO/jobs/${jobEstimateID}/estimate`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-logs':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo job-logs <job_id>';
          const jobLogsID = remainingArgs.trim();
          if (!jobLogsID) return 'Usage: eo job-logs <job_id>';
          try {
            const result = await fetchEndpoint(`EO/jobs/${jobLogsID}/logs`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-results':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo job-results <job_id>';
          const jobResultsID = remainingArgs.trim();
          if (!jobResultsID) return 'Usage: eo job-results <job_id>';
          try {
            const result = await fetchEndpoint(`EO/jobs/${jobResultsID}/results`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-results-create':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: eo job-results-create <job_id> <result_data_json>';
          const [jobResultsCreateID, resultDataJson] = remainingArgs.split(' ');
          if (!jobResultsCreateID || !resultDataJson) return 'Usage: eo job-results-create <job_id> <result_data_json>';
          try {
            const resultData = JSON.parse(resultDataJson);
            const result = await fetchEndpoint(`EO/jobs/${jobResultsCreateID}/results`, {
              method: 'POST',
              data: resultData,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'job-results-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo job-results-delete <job_id>';
          const jobResultsDeleteID = remainingArgs.trim();
          if (!jobResultsDeleteID) return 'Usage: eo job-results-delete <job_id>';
          try {
            const result = await fetchEndpoint(`EO/jobs/${jobResultsDeleteID}/results`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'collections':
          if (remainingArgs.split(' ').length > 1) return 'Usage: eo collections [limit]';
          const collectionsLimit = remainingArgs.trim() || '';
          const collectionsQueryParams = collectionsLimit ? `?limit=${encodeURIComponent(collectionsLimit)}` : '';
          try {
            const result = await fetchEndpoint('EO/collections', { queryParams: collectionsQueryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'collection':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo collection <collection_id>';
          const collectionID = remainingArgs.trim();
          if (!collectionID) return 'Usage: eo collection <collection_id>';
          try {
            const result = await fetchEndpoint(`EO/collections/${collectionID}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'collection-queryables':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: eo collection-queryables <collection_id>';
          const collectionQueryablesID = remainingArgs.trim();
          if (!collectionQueryablesID) return 'Usage: eo collection-queryables <collection_id>';
          try {
            const result = await fetchEndpoint(`EO/collections/${collectionQueryablesID}/queryables`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'service-types':
          if (remainingArgs) return 'Usage: eo service-types (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/service_types');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'services':
          if (remainingArgs) return 'Usage: eo services (no additional arguments required)';
          try {
            const result = await fetchEndpoint('EO/services');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'services-create':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: eo services-create <service_data_json>';
          const serviceDataJson = remainingArgs.trim();
          if (!serviceDataJson) return 'Usage: eo services-create <service_data_json>';
          try {
            const serviceData = JSON.parse(serviceDataJson);
            const result = await fetchEndpoint('EO/services', { method: 'POST', data: serviceData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'service-update':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: eo service-update <service_id> <service_data_json>';
          const [serviceID, serviceUpdateDataJson] = remainingArgs.split(' ');
          if (!serviceID || !serviceUpdateDataJson) return 'Usage: eo service-update <service_id> <service_data_json>';
          try {
            const serviceData = JSON.parse(serviceUpdateDataJson);
            const result = await fetchEndpoint(`EO/services/${serviceID}`, { method: 'PATCH', data: serviceData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'service':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo service <service_id>';
          const serviceGetID = remainingArgs.trim();
          if (!serviceGetID) return 'Usage: eo service <service_id>';
          try {
            const result = await fetchEndpoint(`EO/services/${serviceGetID}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'service-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo service-delete <service_id>';
          const serviceDeleteID = remainingArgs.trim();
          if (!serviceDeleteID) return 'Usage: eo service-delete <service_id>';
          try {
            const result = await fetchEndpoint(`EO/services/${serviceDeleteID}`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'service-logs':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo service-logs <service_id>';
          const serviceLogsID = remainingArgs.trim();
          if (!serviceLogsID) return 'Usage: eo service-logs <service_id>';
          try {
            const result = await fetchEndpoint(`EO/services/${serviceLogsID}/logs`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'files':
          if (remainingArgs.split(' ').length > 1) return 'Usage: eo files [limit]';
          const filesLimit = remainingArgs.trim() || '';
          const filesQueryParams = filesLimit ? `?limit=${encodeURIComponent(filesLimit)}` : '';
          try {
            const result = await fetchEndpoint('EO/files', { queryParams: filesQueryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'file-upload':
          return 'File upload not supported in terminal.';

        case 'file':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo file <path>';
          const filePath = remainingArgs.trim();
          if (!filePath) return 'Usage: eo file <path>';
          try {
            const result = await fetchEndpoint(`EO/files/${filePath}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'file-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: eo file-delete <path>';
          const fileDeletePath = remainingArgs.trim();
          if (!fileDeletePath) return 'Usage: eo file-delete <path>';
          try {
            const result = await fetchEndpoint(`EO/files/${fileDeletePath}`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        default:
          return 'Available commands: eo conformance, eo well-known-open-eo, eo credentials-basic, eo credentials-oidc, eo me, eo processes, eo process-graphs, eo process-graph, eo process-graph-update, eo process-graph-delete, eo file-formats, eo result, eo jobs, eo jobs-create, eo job-update, eo job, eo job-delete, eo job-estimate, eo job-logs, eo job-results, eo job-results-create, eo job-results-delete, eo collections, eo collection, eo collection-queryables, eo service-types, eo services, eo services-create, eo service-update, eo service, eo service-delete, eo service-logs, eo files, eo file-upload, eo file, eo file-delete';
      }
    },
    // KG Commands
    'kg': async (args) => {
      args = args.split(' ');
      const subcommand = args[0];
      const remainingArgs = args.slice(1).join(' ');

      switch (subcommand) {
        case 'start-service':
          if (remainingArgs) return 'Usage: kg start-service (no additional arguments required)';
          try {
            const result = await fetchEndpoint('KG/api/start-service');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'service-status':
          if (remainingArgs) return 'Usage: kg service-status (no additional arguments required)';
          try {
            const result = await fetchEndpoint('KG/api/service-status');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'execute-query':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: kg execute-query <query>';
          const query = remainingArgs.trim();
          if (!query) return 'Usage: kg execute-query <query>';
          try {
            const result = await fetchEndpoint('KG/api/execute-query', {
              queryParams: `?query=${encodeURIComponent(query)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'execute-advanced-query':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: kg execute-advanced-query <query> <sources>';
          const [advQuery, sources] = remainingArgs.split(' ');
          if (!advQuery || !sources) return 'Usage: kg execute-advanced-query <query> <sources>';
          try {
            const result = await fetchEndpoint('KG/api/execute-advanced-query', {
              queryParams: `?query=${encodeURIComponent(advQuery)}&sources=${encodeURIComponent(sources)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-basic-meta-data-set':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: kg search-basic-meta-data-set <values>';
          const basicValues = remainingArgs.trim();
          if (!basicValues) return 'Usage: kg search-basic-meta-data-set <values>';
          try {
            const result = await fetchEndpoint('KG/api/search-basic-MetaDataSet', {
              queryParams: `?values=${encodeURIComponent(basicValues)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-data-set':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: kg search-data-set <values>';
          const dataSetValues = remainingArgs.trim();
          if (!dataSetValues) return 'Usage: kg search-data-set <values>';
          try {
            const result = await fetchEndpoint('KG/api/search-DataSet', {
              queryParams: `?values=${encodeURIComponent(dataSetValues)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-data-set-v1':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: kg search-data-set-v1 <values> <deprecatedFormat>';
          const [v1Values, deprecatedFormat] = remainingArgs.split(' ');
          if (!v1Values || !deprecatedFormat) return 'Usage: kg search-data-set-v1 <values> <deprecatedFormat>';
          try {
            const result = await fetchEndpoint(`KG/api/search-DataSet/v1/Get/${v1Values}/${deprecatedFormat}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-data-set-v2':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: kg search-data-set-v2 <values>';
          const v2Values = remainingArgs.trim();
          if (!v2Values) return 'Usage: kg search-data-set-v2 <values>';
          try {
            const result = await fetchEndpoint(`KG/api/search-DataSet/v2/Get/${v2Values}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-related-data-sets':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: kg search-related-data-sets <id>';
          const relatedID = remainingArgs.trim();
          if (!relatedID) return 'Usage: kg search-related-data-sets <id>';
          try {
            const result = await fetchEndpoint('KG/api/search-related-DataSets', {
              queryParams: `?id=${encodeURIComponent(relatedID)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-full-metadata':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: kg search-full-metadata <id>';
          const metadataID = remainingArgs.trim();
          if (!metadataID) return 'Usage: kg search-full-metadata <id>';
          try {
            const result = await fetchEndpoint('KG/api/search-full-metadata', {
              queryParams: `?id=${encodeURIComponent(metadataID)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-dataset-breakdown':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: kg search-dataset-breakdown <id>';
          const breakdownID = remainingArgs.trim();
          if (!breakdownID) return 'Usage: kg search-dataset-breakdown <id>';
          try {
            const result = await fetchEndpoint(`KG/api/search-dataset-breakdown/${breakdownID}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-dataset-breakdown-post':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: kg search-dataset-breakdown-post <id> <data_json>';
          const [breakdownPostID, breakdownDataJson] = remainingArgs.split(' ');
          if (!breakdownPostID || !breakdownDataJson) return 'Usage: kg search-dataset-breakdown-post <id> <data_json>';
          try {
            const data = JSON.parse(breakdownDataJson);
            const result = await fetchEndpoint(`KG/api/search-dataset-breakdown/${breakdownPostID}`, {
              method: 'POST',
              data,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'search-dataset-breakdown-with-option':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: kg search-dataset-breakdown-with-option <id> <options>';
          const [breakdownOptionID, options] = remainingArgs.split(' ');
          if (!breakdownOptionID || !options) return 'Usage: kg search-dataset-breakdown-with-option <id> <options>';
          try {
            const result = await fetchEndpoint(`KG/api/search-dataset-breakdown-with-option/${breakdownOptionID}`, {
              queryParams: `?options=${encodeURIComponent(options)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'generate-api-call':
          if (!remainingArgs || remainingArgs.split(' ').length !== 3)
            return 'Usage: kg generate-api-call <id> <optionsJson> <downloadOption>';
          const [apiCallID, optionsJson, downloadOption] = remainingArgs.split(' ');
          if (!apiCallID || !optionsJson || !downloadOption)
            return 'Usage: kg generate-api-call <id> <optionsJson> <downloadOption>';
          try {
            const result = await fetchEndpoint('KG/api/generate-api-call', {
              queryParams: `?id=${encodeURIComponent(apiCallID)}&optionsJson=${encodeURIComponent(
                optionsJson,
              )}&downloadOption=${encodeURIComponent(downloadOption)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'generate-api-call-post':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: kg generate-api-call-post <id> <data_json>';
          const [apiCallPostID, apiCallDataJson] = remainingArgs.split(' ');
          if (!apiCallPostID || !apiCallDataJson) return 'Usage: kg generate-api-call-post <id> <data_json>';
          try {
            const data = JSON.parse(apiCallDataJson);
            const result = await fetchEndpoint('KG/api/generate-api-call', {
              method: 'POST',
              queryParams: `?id=${encodeURIComponent(apiCallPostID)}`,
              data,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        default:
          return 'Available commands: kg start-service, kg service-status, kg execute-query, kg execute-advanced-query, kg search-basic-meta-data-set, kg search-data-set, kg search-data-set-v1, kg search-data-set-v2, kg search-related-data-sets, kg search-full-metadata, kg search-dataset-breakdown, kg search-dataset-breakdown-post, kg search-dataset-breakdown-with-option, kg generate-api-call, kg generate-api-call-post';
      }
    },
    // s3 Commands
    's3': async (args) => {
      args = args.split(' ');
      const subcommand = args[0];
      const remainingArgs = args.slice(1).join(' ');

      switch (subcommand) {
        case 'bucket-create':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: s3 bucket-create <bucket_name>';
          const bucketNameCreate = remainingArgs.trim();
          if (!bucketNameCreate) return 'Usage: s3 bucket-create <bucket_name>';
          try {
            const result = await fetchEndpoint('S3/bucket/create', {
              method: 'POST',
              queryParams: `?bucketName=${encodeURIComponent(bucketNameCreate)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'buckets':
          if (remainingArgs) return 'Usage: s3 buckets (no additional arguments required)';
          try {
            const result = await fetchEndpoint('S3/buckets');
            return JSON.stringify(result, null, 2);
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'bucket':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: s3 bucket <bucket_name>';
          const bucketName = remainingArgs.trim();
          if (!bucketName) return 'Usage: s3 bucket <bucket_name>';
          try {
            const result = await fetchEndpoint(`S3/bucket/${bucketName}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'bucket-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: s3 bucket-delete <bucket_name>';
          const bucketNameDelete = remainingArgs.trim();
          if (!bucketNameDelete) return 'Usage: s3 bucket-delete <bucket_name>';
          try {
            const result = await fetchEndpoint('S3/bucket/delete', {
              method: 'DELETE',
              queryParams: `?bucketName=${encodeURIComponent(bucketNameDelete)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'bucket-files-upload':
          return 'File upload not supported in terminal.';

        case 'bucket-files':
          if (!remainingArgs || remainingArgs.split(' ').length > 2)
            return 'Usage: s3 bucket-files <bucket_name> [prefix]';
          const [bucketNameFiles, prefix = ''] = remainingArgs.split(' ');
          if (!bucketNameFiles) return 'Usage: s3 bucket-files <bucket_name> [prefix]';
          const queryParams = prefix ? `?prefix=${encodeURIComponent(prefix)}` : '';
          try {
            const result = await fetchEndpoint(`S3/bucket/${bucketNameFiles}/files`, { queryParams });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'bucket-files-preview':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: s3 bucket-files-preview <bucket_name> <file_path_data_json>';
          const [bucketNamePreview, filePathDataJson] = remainingArgs.split(' ');
          if (!bucketNamePreview || !filePathDataJson)
            return 'Usage: s3 bucket-files-preview <bucket_name> <file_path_data_json>';
          try {
            const filePathData = JSON.parse(filePathDataJson);
            const result = await fetchEndpoint(`S3/bucket/${bucketNamePreview}/files/preview`, {
              method: 'POST',
              data: filePathData,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'bucket-files-delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: s3 bucket-files-delete <bucket_name> <key>';
          const [bucketNameDeleteFiles, key] = remainingArgs.split(' ');
          if (!bucketNameDeleteFiles || !key) return 'Usage: s3 bucket-files-delete <bucket_name> <key>';
          try {
            const result = await fetchEndpoint(`S3/bucket/${bucketNameDeleteFiles}/files/delete`, {
              method: 'DELETE',
              queryParams: `?key=${encodeURIComponent(key)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'files-upload':
          return 'File upload not supported in terminal.';

        case 'files-upload-conf':
          return 'File upload not supported in terminal.';

        case 'files-js-def':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1)
            return 'Usage: s3 files-js-def <file_config_json>';
          const fileConfigJson = remainingArgs.trim();
          if (!fileConfigJson) return 'Usage: s3 files-js-def <file_config_json>';
          try {
            const fileConfig = JSON.parse(fileConfigJson);
            const result = await fetchEndpoint('S3/files/js/def', { method: 'POST', data: fileConfig });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        default:
          return 'Available commands: s3 bucket-create, s3 buckets, s3 bucket, s3 bucket-delete, s3 bucket-files-upload, s3 bucket-files, s3 bucket-files-preview, s3 bucket-files-delete, s3 files-upload, s3 files-upload-conf, s3 files-js-def';
      }
    },
    // Users Commands
    'users': async (args) => {
      args = args.split(' ');
      const subcommand = args[0];
      const remainingArgs = args.slice(1).join(' ');

      switch (subcommand) {
        case 'count':
          if (remainingArgs) return 'Usage: users count (no additional arguments required)';
          try {
            const result = await fetchEndpoint('Users/count');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'list':
          if (remainingArgs) return 'Usage: users list (no additional arguments required)';
          try {
            const result = await fetchEndpoint('Users');
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'create':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: users create <user_data_json>';
          const userDataJson = remainingArgs.trim();
          if (!userDataJson) return 'Usage: users create <user_data_json>';
          try {
            const userData = JSON.parse(userDataJson);
            const result = await fetchEndpoint('Users', { method: 'POST', data: userData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'get':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: users get <id>';
          const userId = remainingArgs.trim();
          if (!userId) return 'Usage: users get <id>';
          try {
            const result = await fetchEndpoint(`Users/${userId}`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'update':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: users update <id> <user_data_json>';
          const [updateId, updateUserDataJson] = remainingArgs.split(' ');
          if (!updateId || !updateUserDataJson) return 'Usage: users update <id> <user_data_json>';
          try {
            const userData = JSON.parse(updateUserDataJson);
            const result = await fetchEndpoint(`Users/${updateId}`, { method: 'PUT', data: userData });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'delete':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: users delete <id>';
          const deleteId = remainingArgs.trim();
          if (!deleteId) return 'Usage: users delete <id>';
          try {
            const result = await fetchEndpoint(`Users/${deleteId}`, { method: 'DELETE' });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'history':
          if (!remainingArgs || remainingArgs.split(' ').length > 3)
            return 'Usage: users history <id> [page] [pageSize]';
          const [historyId, page = '1', pageSize = '20'] = remainingArgs.split(' ');
          if (!historyId) return 'Usage: users history <id> [page] [pageSize]';
          try {
            const result = await fetchEndpoint(`Users/${historyId}/history`, {
              queryParams: `?page=${encodeURIComponent(page)}&pageSize=${encodeURIComponent(pageSize)}`,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'credentials':
          if (!remainingArgs || remainingArgs.split(' ').length !== 1) return 'Usage: users credentials <id>';
          const credentialsId = remainingArgs.trim();
          if (!credentialsId) return 'Usage: users credentials <id>';
          try {
            const result = await fetchEndpoint(`Users/${credentialsId}/credentials`);
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        case 'reset-password':
          if (!remainingArgs || remainingArgs.split(' ').length !== 2)
            return 'Usage: users reset-password <id> <password_data_json>';
          const [resetId, passwordDataJson] = remainingArgs.split(' ');
          if (!resetId || !passwordDataJson) return 'Usage: users reset-password <id> <password_data_json>';
          try {
            const passwordData = JSON.parse(passwordDataJson);
            const result = await fetchEndpoint(`Users/${resetId}/reset-password`, {
              method: 'PUT',
              data: passwordData,
            });
            return result;
          } catch (e) {
            return 'Error: ' + e.message;
          }

        default:
          return 'Available commands: users count, users list, users create, users get, users update, users delete, users history, users credentials, users reset-password';
      }
    },
  };
  const welcomeMessage = (
    <div style={{ marginBottom: 15 }}>
      <div>
        <img src={logo} style={{ width: 200 }} />
      </div>
      <span>Type "help" for all available commands.</span>
    </div>
  );
  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollableRef.current) {
        scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      }
    }, 0);
  };

  // Enhanced command handler that wraps all commands to ensure scrolling
  const wrapCommandsWithScroll = (originalCommands) => {
    const wrappedCommands = {};

    for (const [command, handler] of Object.entries(originalCommands)) {
      wrappedCommands[command] = (...args) => {
        const result = handler(...args);
        scrollToBottom();
        return result;
      };
    }

    // Add a help command if it doesn't exist
    if (!wrappedCommands.help) {
      wrappedCommands.help = () => {
        scrollToBottom();
        return 'Available commands: ' + Object.keys(wrappedCommands).join(', ');
      };
    }

    return wrappedCommands;
  };

  useEffect(() => {
    if (terminalRef.current) {
      const observer = new MutationObserver(() => {
        scrollToBottom();
      });

      observer.observe(terminalRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        scrollToBottom();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    keycloak.loadUserInfo().then((response) => {
      console.log(response);
      setPrompt('$' + response.preferred_username);
    });
  }, []);

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <TerminalContextProvider>
        <div ref={terminalRef} style={{ height: '100vh', overflow: 'hidden' }}>
          <ReactTerminal
            prompt={prompt}
            theme={theme}
            showControlBar={controlBar}
            showControlButtons={controlButtons}
            welcomeMessage={welcomeMessage}
            commands={commands}
            onInput={scrollToBottom}
          />
        </div>
      </TerminalContextProvider>
    </div>
  );
}
