import React from 'react';
import CustomTerminal from '../components/CustomTerminal';

export default function TerminalWrapper() {
  return (
    <div style={{ background: 'rgb(19, 21, 25)' }}>
      <CustomTerminal />
    </div>
  );
}
